import Swiper, { Navigation, Pagination, Lazy} from 'swiper';
import CartDrawer from './cartDrawer';

window.$window = $(window);
window.$body = $("body");
window.page = $body.data("page");
window.$header = $("#header");
window.$wrapper = $("#wrapper");
window.spaceToHeaderFixed;
window.language = $("html").attr("lang");
window.timeTransitionDefault = 350;
window.texts = [];

texts["pt-BR"] = {
  quantity: "quantidade",
  price: "preço",
  cart_empty: "O seu carrinho está vazio",
  go_product: "Ir para a página do produto",
  btn_edit_cart: "Editar pedido",
  btn_checkout: "Finalizar pedido",
  btn_success: "Adicionado!",
  errorsTxt: {
    withoutSku: "Selecione um atributo para o produto",
  },
};

function getText(key, subKey) {
  if (typeof subKey == "undefined") {
    return texts[language][key];
  } else {
    return texts[language][key][subKey];
  }
}

window.store = {
  config: {
    // Seletor do botão de comprar
    selectorBtnBuy: '[data-action="add-cart"]',
    selectorBtnBuyAssinatura: '[data-action="add-cart-assinatura"]',
    // Seletor do elemento que engloba um produto
    selectorWrapperProduct: "[data-box-produto]",
    selectorWrapperProductAssinatura: "[data-box-produto-assinatura]",
    selectorFormProduct: "[data-form-product]",
    selectorFormProductAssinatura: "[data-form-product-assinatura]",
    // Se a store tem versão internacional ou não
    hasInternacionalVersion: false,
    errorsTxt: {
      withoutSku: getText("errorsTxt", "withoutSku"),
    },
    // configuração de comportamento ao add um produto
    addToCartOpenCartpopup: true,
    addToCartOpenCartpopupMobile: true,
    cartCount: {
      selector: "[data-cart-count]",
      attr: "[data-cart-count-attr]",
      text: "[data-cart-item-text]",
    },
  },
  formatMoney: function (value) {
    // FORMATA UM VALOR
    return (
      "R$ " +
      value
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+\,)/g, "$1.")
    );
  },
  validateQuantity: function (_val) {
    // VALIDA SE A QUANTIDADE INFORMADA É UM NÚMERO
    if (!isNaN(_val)) {
      if (parseInt(_val) > 0) {
        return true;
      }
    }

    return false;
  },
  getClearNumber: function (_val) {
    // RETORNA UM NÚMERO LIMPO COMO INT
    if (!isNaN(_val)) {
      clearNumber = parseInt(_val);

      return clearNumber;
    }

    return false;
  },
  validateFormProduct: function (_$form) {
    // VALIDA O FORM DE PRODUTO PARA VER SE O PRODUTO PODE SER ADICIONADO
    var $parent = _$form.closest("[data-box-produto]");
    var $btnComprar = _$form.find('[data-action="add-cart"]');
    var validated = true;
    var errorsTxt = [];

    if ($btnComprar.data("available") == "false") {
      validated = false;
      errorsTxt.push("");
    } else {
      //  verifica se o sku foi selecionado
      if (_$form.find('[name="sku"]').val() == "") {
        validated = false;
        errorsTxt.push("Selecione um atributo para o produto");
      }

      //  verifica se a quantidade é válida
      console.log(_$form.find('input[name="quantity"]').val());
      if (_$form.find('input[name="quantity"]').val() <= 0) {
        console.log("qtd invalida");
        validated = false;
        errorsTxt.push("Quantidade indisponível");
      }
    }

    return { validated: validated, errors: errorsTxt };
  },
  setRespValidateProduct: function (_resp, _$form, _$boxResponse) {
    // var $boxResult = _$form.find('.resp-validate');
    var $boxResult = _$boxResponse;
    var htmlErrors = "";

    if (_resp.validated) {
      $boxResult.empty();
    } else {
      for (var i = _resp.errors.length - 1; i >= 0; i--) {
        htmlErrors += '<span class="msg error">' + _resp.errors[i] + "</span>";
      }

      $boxResult.html(htmlErrors);
    }
  },
  addItemResultDefault: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == "produto-adicionado") {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuy);

      $btnComprar.addClass("success").html("Produto adicionado!");

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar
          .removeClass("success")
          .html($btnComprar.data("text-available"));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlCart;
      }, 150);
    } else if (_typeResult == "erro-adicionar") {
      var errorText = JSON.parse(_result.responseText);

      if (typeof _$boxResponse != "undefined" && _$boxResponse.length > 0) {
        console.info(errorText);

        _$boxResponse.addClass("error");
        _$boxResponse.removeClass("success");

        _$boxResponse.find("span").html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  addItemResultDefaultAssinatura: function (
    _$form,
    _typeResult,
    _result,
    _$boxResponse
  ) {
    var _this = this;
    if (_typeResult == "produto-adicionado") {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuyAssinatura);

      $btnComprar.addClass("success").html("Adicionado!");

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar
          .removeClass("success")
          .html($btnComprar.data("text-available"));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlAssinaturaCart;
      }, 150);
    } else if (_typeResult == "erro-adicionar") {
      if (typeof _result == "object") {
        var errorText = _result;
      } else {
        var errorText = JSON.parse(_result);
      }

      if (typeof _$boxResponse != "undefined" && _$boxResponse.length > 0) {
        console.info(errorText);

        _$boxResponse.addClass("error");
        _$boxResponse.removeClass("success");

        _$boxResponse.find("span").html(errorText.error);
        _$boxResponse.slideDown(350);
      }
    }
  },
  addItem: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find('[data-action="add-cart"]');
    var urlAdd = "/carrinho/adicionar";
    var urlUpdate = "/carrinho";
    var url = urlAdd;
    var $boxResponse = _$parent.find(
      "[data-form-product] .msg-response:not(.resp-validate)"
    );
    var quantity = _$form.find('input[name="quantity"]').val();

    console.info("addItem");
    console.info("addItem", _$form.serializeArray());
    
    let form = _$form.serializeArray();

    //Repete as personalizações para quantidade maior que 1
    let customizations = form.filter(field => field.name.includes('customizations'));
    for (let i = 1; i < quantity; i++) {
      form = form.concat(...customizations)
    }
    console.log('quantity', quantity)
    console.log('customizations', customizations)
    console.log('finalForm:', form);

    if (!$btnComprar.hasClass("adding")) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: form,
        beforeSend: function () {
          $btnComprar.addClass("adding");
        },
      })
        .done(function (resp) {
          console.log("success");
          console.info(resp);

          if (typeof template_store.addItemResult == "function") {
            template_store.addItemResult(
              _$form,
              "produto-adicionado",
              resp,
              $boxResponse
            );
          } else {
            template_store.addItemResultDefault(
              _$form,
              "produto-adicionado",
              resp,
              $boxResponse
            );
          }
        })
        .fail(function (resp) {
          console.log("error");
          console.error(resp);
          console.error(resp.responseText);
          console.error(resp.responseText.error);

          if (typeof template_store.addItemResult == "function") {
            template_store.addItemResult(
              _$form,
              "erro-adicionar",
              resp,
              $boxResponse
            );
          } else {
            template_store.addItemResultDefault(
              _$form,
              "erro-adicionar",
              resp,
              $boxResponse
            );
          }
        })
        .always(function () {
          console.log("complete");
          $btnComprar.removeClass("adding");
        });
    }
  },
  addItemAssinatura: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find(store.config.selectorBtnBuyAssinatura);
    var url = urlAssinaturaCartAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find(
      store.config.selectorFormProductAssinatura +
        " .msg-response:not(.resp-validate)"
    );
    var data = {};

    data.subscription_item = {
      quantity: _$form.find('[name="quantity"]').val(),
      sku: _$form.find('[name="sku"]').val(),
      extra: {
        vindi_plan_id: _$form.find('[name="extra[vindi_plan_id]"]').val(),
      },
    };

    console.info("addItemAssinatura");
    console.info("addItemAssinatura", data);
    console.info("addItemAssinatura", JSON.stringify(data));

    if (!$btnComprar.hasClass("adding")) {
      $.ajax({
        url: url,
        type: "POST",
        // dataType: 'json',
        contentType: "application/json",
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        beforeSend: function () {
          $btnComprar.addClass("adding");
        },
      })
        .done(function (resp) {
          console.log("success");
          console.info(resp);

          if (typeof template_store.addItemResult == "function") {
            template_store.addItemResult(
              _$form,
              "produto-adicionado",
              resp,
              $boxResponse
            );
          } else {
            template_store.addItemResultDefaultAssinatura(
              _$form,
              "produto-adicionado",
              resp,
              $boxResponse
            );
          }
        })
        .fail(function (xhr, status, error) {
          console.log("error");
          console.error(xhr);
          console.error(status);
          console.error(error);

          var resp = {
            error:
              "Houve um problema ao adicionar. Tente mais tarde ou entre em contato.",
          };

          if (typeof template_store.addItemResult == "function") {
            template_store.addItemResult(
              _$form,
              "erro-adicionar",
              resp,
              $boxResponse
            );
          } else {
            template_store.addItemResultDefaultAssinatura(
              _$form,
              "erro-adicionar",
              resp,
              $boxResponse
            );
          }
        })
        .always(function () {
          console.log("complete");
          $btnComprar.removeClass("adding");
        });
    }
  },
  deleteItem: function (_itemId, _$item, removeItemResult) {
    var _this = this;

    if (!_$item.hasClass("removing")) {
      $.ajax({
        url: "/carrinho",
        type: "POST",
        dataType: "json",
        data: {
          _method: "delete",
          item_id: _itemId,
        },
        beforeSend: function () {
          _$item.addClass("removing");
        },
      })
        .done(function (_cart) {
          console.log("success");

          if ($body.width() > 768) {
            if (store.config.addToCartOpenCartpopup) {
              template_store.headerCart.loadCart(_cart);
              template_store.headerCart.hide();
            }
          } else {
            if (store.config.addToCartOpenCartpopupMobile) {
              template_store.headerCart.loadCart(_cart);
              template_store.headerCart.hide();
            }
          }

          if (typeof removeItemResult == "function") {
            removeItemResult(_$item, _cart);
          }
        })
        .fail(function (error) {
          console.log("erro ao remover", error.responseText);
        })
        .always(function () {
          _$item.removeClass("removing");
        });
    }
  },
  setMsgResponse: function (_msg, _type, _$parent) {
    /*
      O tipo pode conter um dos 3 valores: success, warning, error ou CLEAR.
      O type CLEAR limpa as mensagens e faz sumir a mensagem
    */

    // Verifica se não há mais de uma mensagem
    // caso tenha, gera um html para cada mensagem
    if (Array.isArray(_msg)) {
      var msgArr = _msg;
      _msg = "";
      for (var i = msgArr.length - 1; i >= 0; i--) {
        _msg += '<span class="msg error">' + msgArr[i] + "</span>";
      }
    }

    _$parent
      .find("[data-msg-retorno]")
      .removeClass("success")
      .removeClass("warning")
      .removeClass("error");

    _$parent.find("[data-msg-retorno] [data-msg]").empty();

    if (_type != "clear") {
      _$parent.find("[data-msg-retorno]").addClass(_type);
      _$parent.find("[data-msg-retorno] [data-msg]").html(_msg);
    }
  },
  updateCartCount: function (_itemsCount) {
    var _this = this;

    // $(_this.cartCount.selector).empty().text(_itemsCount);

    $(_this.config.cartCount.attr).data("cart-count-attr", _itemsCount);
    $(_this.config.cartCount.attr).attr("data-cart-count-attr", _itemsCount);

    $(_this.config.cartCount.attr).html(_itemsCount);
    $(_this.config.cartCount.text).html(_itemsCount);
    $(_this.config.cartCount.textLabel).html(
      _itemsCount > 1 ? "itens" : "item"
    );

    if (_itemsCount > 0) {
      $("[data-cart-body]").parent().addClass("active");
    } else {
      $("[data-cart-body]").parent().removeClass("active");
    }
  },
  getCartCount: function () {
    async function getCartItens() {
      let itens;

      try {
        itens = await $.ajax({
          url: "/carrinho/itens",
          type: "GET",
          dataType: "text",
        });

        return itens;
      } catch (error) {
        console.error(error);
      }
    }

    if ($(store.config.cartCount.selector).length > 0) {
      getCartItens().then((total) => {
        var itens = Number.isInteger(parseInt(total));
        if (itens) {
          $(store.config.cartCount.attr).attr("data-cart-count-attr", total);
          $(store.config.cartCount.selector).html(total);

          var $boxCart = $("[data-cart-body]");

          if ($boxCart.length > 0) {
            if (total > 0) {
              template_store.headerCart.loadCart();
            } else {
              $boxCart.html(
                '<div class="empty"><p>Seu carrinho está vazio...</p></div>'
              );
            }
          }
        }
      });
    }
  },
  submitForm: function (_$form) {
    _$form.find('[name="reply_to"]').val(_$form.find('[name="email"]').val());

    if (!_$form.hasClass("sending")) {
      $.ajax({
        url: "/webform",
        type: "POST",
        data: _$form.serialize(),
        beforeSend: function () {
          _$form.addClass("sending");
        },
      })
        .done(function () {
          console.info("foi");
          // store.setMsgResponse('Mensagem enviada com sucesso!', 'success', _$form);
          _$form
            .find("[data-msg-retorno] [data-msg-success]")
            .addClass("alert-success visible");
          _$form.find("button[type=submit]").text("Enviado");

          setTimeout(function () {
            _$form.find("button[type=submit]").text("Enviar");
            _$form
              .find("[data-msg-retorno] [data-msg-success]")
              .removeClass("alert-success visible");
          }, 3500);
          _$form[0].reset();
        })
        .fail(function () {
          // console.info('foi, mas deu ruim');
          _$form
            .find("[data-msg-retorno] [data-msg-error]")
            .addClass("alert-error visible");
          _$form.find("button[type=submit]").text("Falha ao enviar");
          setTimeout(function () {
            _$form.find("button[type=submit]").text("Enviar");
            _$form
              .find("[data-msg-retorno] [data-msg-error]")
              .removeClass("alert-error visible");
          }, 3500);
          // store.setMsgResponse('Houve um problema ao enviar sua mensagem, tente novamente! Ou entre em contato conosco pelo telefone (21) 99644-6925 ', 'error', $form);
        })
        .always(function () {
          console.log("complete");
          _$form.removeClass("sending");
        });
    }
  },
  login: {
    validateLogin: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == "") {
        validated = false;
        errorsTxt.push("Informe seu email!");
      }

      if (_$form.find('[name="password"]').val() == "") {
        validated = false;
        errorsTxt.push("Informe a senha!");
      }

      return { validated: validated, errors: errorsTxt };
    },
    validateRegister: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == "") {
        validated = false;
        errorsTxt.push("Informe seu email!");
      }

      if (_$form.find('[name="password"]').val() == "") {
        validated = false;
        errorsTxt.push("Informe uma senha!");
      }

      if (_$form.find('[name="password"]').val() != "") {
        var pass = _$form.find('[name="password"]').val();
        var confirmationPass = _$form
          .find('[name="password_confirmation"]')
          .val();

        if (pass != confirmationPass) {
          validated = false;
          errorsTxt.push("Senha e confirmação são diferentes!");
        }
      }

      return { validated: validated, errors: errorsTxt };
    },
    validateRecover: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == "") {
        validated = false;
        errorsTxt.push("Informe seu email!");
      }

      return { validated: validated, errors: errorsTxt };
    },
    logar: function (_$form) {
      if (!_$form.hasClass("sending")) {
        $.ajax({
          url: "/entrar",
          type: "POST",
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass("sending");
          },
        })
          .done(function (resp) {
            console.log("success", resp);

            if (resp.error) {
              store.setMsgResponse(resp.error, "error", _$form);
            }

            if (resp.client) {
              window.location.href = resp.redirect_to;
            }
          })
          .fail(function (resp) {
            console.error("error", error.responseText);
          })
          .always(function () {
            console.log("complete");
            _$form.removeClass("sending");
          });
      }
    },
    recoverPassord: function (_$form) {
      if (!_$form.hasClass("sending")) {
        $.ajax({
          url: "/recuperar_senha",
          type: "POST",
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass("sending");
          },
        })
          .done(function (resp) {
            console.log("success", resp);

            if (resp.error) {
              store.setMsgResponse(resp.error, "error", _$form);
            }

            if (resp.ok) {
              store.setMsgResponse(resp.ok, "success", _$form);
            }

            /*if (resp.client) {
              window.location.href = resp.redirect_to;
            }*/
          })
          .fail(function (resp) {
            console.error("error", error.responseText);
          })
          .always(function () {
            console.log("complete");
            _$form.removeClass("sending");
          });
      }
    },
  },
};

window.template_store = {
  /* 
    Manda um ajax para o form de avise-me e também para um form da plataforma para o cliente receber um email
  */
  submitFormNotify: function (_$form) {
    var formData = _$form.serialize();

    if (!_$form.hasClass("sending")) {
      $.ajax({
        url: _$form.attr("action"),
        type: "post",
        dataType: "json",
        data: formData,
        beforeSend: function () {
          _$form.addClass("sending");
        },
      })
        .done(function (resp) {
          console.log("success");
          console.info(resp);

          var msgSuccess =
            "Você receberá um e-mail quando o produto estiver disponível";

          if (resp.error) {
            _$form.find("[data-msg-retorno] [data-msg-error]").html(resp.error);
            _$form
              .find("[data-msg-retorno] [data-msg-success]")
              .removeClass("visible");
            _$form
              .find("[data-msg-retorno] [data-msg-error]")
              .addClass("visible");
          } else {
            // Envia um email para o cliente saber que o usuário solicitou o avise-me
            $.ajax({
              url: "/webform",
              type: "POST",
              data: {
                key: _$form.find('[name="key"]').val(),
                reply_to: _$form.find('[name="email"]').val(),
                email: _$form.find('[name="email"]').val(),
                product_name: _$form.find('[name="nome"]').val(),
                referencia: _$form.find('[name="referencia"]').val(),
                sku: _$form.find('[name="sku"]').val(),
              },
            })
              .done(function (resp) {
                _$form
                  .find("[data-msg-retorno] [data-msg-success]")
                  .addClass("visible");
                _$form
                  .find("[data-msg-retorno] [data-msg-error]")
                  .removeClass("visible");

                _$form[0].reset();
              })
              .fail(function (error) {
                _$form
                  .find("[data-msg-retorno] [data-msg-success]")
                  .removeClass("visible");
                _$form
                  .find("[data-msg-retorno] [data-msg-error]")
                  .addClass("visible");
              });
          }
        })
        .fail(function (resp) {
          console.log("error");
          console.error(resp);
          console.error(resp.responseText);
          console.error(resp.responseText.error);

          var errorText = JSON.parse(resp.responseText);
          console.info(errorText);

          _$form
            .find("[data-msg-retorno] [data-msg-error]")
            .html(errorText.error);
          _$form
            .find("[data-msg-retorno] [data-msg-success]")
            .removeClass("visible");
          _$form
            .find("[data-msg-retorno] [data-msg-error]")
            .addClass("visible");
        })
        .always(function () {
          _$form.removeClass("sending");
        });
    }
  },
  /* 
    Função que executa o comportamento de quando o produto é adicionado no carrinho
  */
  addItemResult: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this;

    if (_typeResult == "produto-adicionado") {
      var $btnComprar = _$form.find(store.config.selectorBtnBuy);

      $btnComprar.addClass("success").html(getText(language));

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar
          .removeClass("success")
          .html($btnComprar.data("text-available"));
      }, 3500);

      if ($body.width() > 768) {
        if (store.config.addToCartOpenCartpopup) {
          template_store.headerCart.loadCart(_result);
          // template_store.headerCart.show();
          CartDrawer.show()
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      } else {
        if (store.config.addToCartOpenCartpopupMobile) {
          template_store.headerCart.loadCart(_result);
          // template_store.headerCart.show();
          CartDrawer.show()
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      }
    } else if (_typeResult == "erro-adicionar") {
      var errorText = JSON.parse(_result.responseText);

      if (typeof _$boxResponse != "undefined" && _$boxResponse.length > 0) {
        $("html,body").animate(
          {
            scrollTop: _$boxResponse.offset().top,
          },
          "250"
        );

        console.info(errorText);

        _$boxResponse.addClass("error");
        _$boxResponse.removeClass("success");

        _$boxResponse.find("span").html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  headerCart: {
    initDrawer: function () {
      var button = document.getElementById("bt-drawer");
      if (button) {
        button.addEventListener("click", window.componentCartDrawer.toggle);
      }

      // vnda.loadCartPopup(function (html) {
      //   $boxCart.empty();
      //   $boxCart.append(html);
      //   //console.log(html)
      // });
    },
    loadCart: function (total) {
      store.updateCartCount(total);
    },
    show: function () {
      window.componentCartDrawer.open();
    },
  },
  /*
    Debounce retorna uma função que enquanto continuar sendo chamada não é executada
    A função só será executada quando para de ser chamada por N milisegundos
    Útil para melhorar a performance de códigos que são executados muitas vezes por segundo, como o $(window).resize()

    Ex:
    
    $(window).resize(debounce(function() {
      // código a ser executado
    }, 500))
    
    No exemplo acima a função só será executada 500ms depois do último resize
    Abra o link abaixo e redimensione a janela branca e acompanhe o output do console
    Exemplo codepen: https://codepen.io/valkervieira/pen/oNgqyWY

    Um caso comum de uso é em lojas onde a seleção de um filtro na página de tag recarrega automáticamente a página
    Com o debounce o usuário pode escolher vários filtros rapidamente e a página só recarrega quando parar de escolher
  */
  debounce: function (func, wait, immediate = true) {
    var timeout;

    return function () {
      var context = this,
        args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  },

  /*
    Throttle diminui a frequencia que uma função é executada
    Enquanto no debounce a função só é executada quando para de ser chamada, no throttle ela
    continua sendo executada só que em um intervalo mínimo de N milisegundos (default = 250)

    Ex:

    $(window).resize(throttle() {
      // código a ser executado
    }, 500)

    No exemplo acima a função resize é chamada várias vezes por segundo mas só é executada 1 vez a cada 500ms
    Abra o link abaixo, redimensione a janela branca e acompanhe o console
    Exemplo codepen: https://codepen.io/valkervieira/pen/yLyKEPW

    Um caso comum de uso é checar se o scroll passou de um determinado ponto, para fixar um header ou alterar algum elemento do DOM
  */
  throttle: function (fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;

      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  },
  home: {
    setFullbanner: () => {
      const fullbanners = document.querySelectorAll('[data-fullbanner]');
  
      fullbanners.forEach(section => {
        const carousel = section.querySelector('.swiper');
        const pagination = section.querySelector('.swiper-pagination');
        const next = section.querySelector('.swiper-button-next');
        const prev = section.querySelector('.swiper-button-prev');
  
        const fullbanner = new Swiper(carousel, {
          modules: [Navigation, Lazy],
          slidesPerView: 1,
          watchOverflow: true,
          preloadImages: false,
          autoplay:{
            speed: 2000
          },
          lazy: {
            checkInView: true,
            loadPrevNext: false,
            loadOnTransitionStart: true,
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: '-disabled'
          },
        });

        fullbanner.autoplay.stop();
        const setAutoPlay = () => {
          window.removeEventListener('scroll', setAutoPlay);
          window.removeEventListener('mousemove', setAutoPlay);
          fullbanner.autoplay.start();
        }
        window.addEventListener('scroll', setAutoPlay);
        window.addEventListener('mousemove', setAutoPlay);

      });
    },
    setProductsNewest: () => {
      const newestProducts = document.querySelectorAll('[data-products-newest]');
  
      newestProducts.forEach(section => {
        const carousel = section.querySelector('.swiper');
        const pagination = section.querySelector('.swiper-pagination');
        const next = section.querySelector('.swiper-button-next.newest');
        const prev = section.querySelector('.swiper-button-prev.newest');
  
        const newestProductsSlider = new Swiper(carousel, {
          slidesPerView: 3,
          watchOverflow: true,
          preloadImages: false,
          autoplay:{
            speed: 2000
          },
          lazy: {
            checkInView: true,
            loadPrevNext: false,
            loadOnTransitionStart: true,
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: '-disabled'
          },
          breakpoints:{
            320:{
              slidesPerView: 2,
              spaceBetween: 15
            },
            992:{
              slidesPerView: 3,
              spaceBetween: 15
            }
          }
        });

        newestProductsSlider.autoplay.stop();
        const setAutoPlay = () => {
          console.log('autoplay')
          window.removeEventListener('scroll', setAutoPlay);
          window.removeEventListener('mousemove', setAutoPlay);
          newestProductsSlider.autoplay.start();
        }
        window.addEventListener('scroll', setAutoPlay);
        window.addEventListener('mousemove', setAutoPlay);
      });
    },
    setProductsBestSellers: () => {
      const bestsellersProducts = document.querySelectorAll('[data-products-bestsellers]');
  
      bestsellersProducts.forEach(section => {
        const carousel = section.querySelector('.swiper');
        const pagination = section.querySelector('.swiper-pagination');
        const next = section.querySelector('.swiper-button-next.bestsellers');
        const prev = section.querySelector('.swiper-button-prev.bestsellers');
  
        new Swiper(carousel, {
          slidesPerView: 3,
          watchOverflow: true,
          preloadImages: false,
          autoplay:{
            speed: 2000
          },
          lazy: {
            checkInView: true,
            loadPrevNext: false,
            loadOnTransitionStart: true,
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: '-disabled'
          },
          breakpoints:{
            320:{
              slidesPerView: 2,
              spaceBetween: 15
            },
            992:{
              slidesPerView: 4,
              spaceBetween: 15
            }
          }
        });
      });
    },
    // Aqui ficam os js da home
    init: function () {
      var _this = this;

      _this.setFullbanner();
      _this.setProductsNewest();
      _this.setProductsBestSellers();
    },
  },
  product: {
    // Aqui ficam os js do transacional
    $sectionProduct: $("[data-section-product]"),
    $wrapperImages: $("[data-section-product]").find("[data-product-images]"),
    $sliderProduct: $("[data-section-product]").find(
      "[data-product-images] [data-main-slider]"
    ),
    $thumbsProduct: $("[data-section-product]").find(
      "[data-product-images] [data-slider-thumbs]"
    ),
    sliderProduct: [],
    /* 
      Exibe as primeiras imagens do produto
      ** Importante para quando houver imagem por sku **
    */
    showFirstImages: function () {
      var _this = this;
      var variants = vnda.variants.all();
      var $imagesWithoutSkus = _this.$sliderProduct.find(".image-without-sku");
      var $thumbsWithoutSkus = _this.$thumbsProduct.find(".image-without-sku");

      if ($imagesWithoutSkus.length > 0) {
        /* 
          Todas as imagens sem skus são exibidas sempre
        */

        $imagesWithoutSkus.removeClass("inactive").addClass("active");

        $thumbsWithoutSkus.removeClass("inactive").addClass("active");
      } else {
        /* 
          Percorre todas as variantes
        */
        for (var i = 0; i < variants.length; i++) {
          if (variants[i].main) {
            console.info(variants[i]);

            // Procura a imagem que contém o sku e a exibe
            _this.$sliderProduct
              .find('[data-image][data-skus-image*="' + variants[i].sku + '"]')
              .addClass("active")
              .removeClass("inactive");

            _this.$thumbsProduct
              .find(
                '[data-image-thumb][data-skus-image*="' + variants[i].sku + '"]'
              )
              .addClass("active")
              .removeClass("inactive");
          }
        }
      }
    },
    setQuantityProduct: function () {
      var quantityInput = document.querySelectorAll("[data-quantity-selector]");

      if (quantityInput) {
        $(document).on(
          "click",
          "[data-quantity-selector] [data-plus-quantity]",
          function () {
            var $box = $(this).closest("[data-quantity-selector]");
            var $input = $box.find('input[name="quantity"]');
            var val = $input.val();

            // console.log('maisssss')

            if (store.validateQuantity(val)) {
              var clearNumber = store.getClearNumber(val);
              $input.val(parseInt(clearNumber) + 1);
              console.log('maaais', $input.val(parseInt(clearNumber) + 1))
            }
          }
        );

        $(document).on(
          "click",
          "[data-quantity-selector] [data-minus-quantity]",
          function () {
            var $box = $(this).closest("[data-quantity-selector]");
            var $input = $box.find('input[name="quantity"]');
            var val = $input.val();

            // console.log('menosssss')

            if (store.validateQuantity(val)) {
              var clearNumber = store.getClearNumber(val);
              if (parseInt(clearNumber) - 1 > 0) {
                $input.val(parseInt(clearNumber) - 1);

                console.log('menooos', $input.val(parseInt(clearNumber) - 1))
              }
            }
          }
        );
      }
    },
    setGallery: function () {
      var _this = this;

      var galleryThumbs = new Swiper(".swiper-container[data-slider-thumbs]", {
        direction: "vertical",
        spaceBetween: 5,
        slidesPerView: 3,

        breakpoints: {
          992: {
            direction: "vertical",
          },
          200: {
            slidesPerView: 4,
            direction: "horizontal",
          },
        },
        navigation: {
          nextEl: $(".swiper-container[data-slider-thumbs]").find(
            ".swiper-button-next"
          ),
          prevEl: $(".swiper-container[data-slider-thumbs]").find(
            ".swiper-button-prev"
          ),
        },
      });

      var gallery = new Swiper(_this.$sliderProduct, {
        direction: "horizontal",
        slidesPerView: 1,
        thumbs: {
          swiper: galleryThumbs,
        },
        navigation: {
          nextEl: $(".swiper-container[data-main-slider]").find(
            ".swiper-button-next"
          ),
          prevEl: $(".swiper-container[data-main-slider]").find(
            ".swiper-button-prev"
          ),
        },
      });

      this.sliderProduct.push(gallery);
      this.sliderProduct.push(galleryThumbs);

      $(document).on("click", "[data-image-thumb]:visible", function () {
        gallery.slideTo($(this).index("[data-image-thumb]:visible"));
      });
    },
    submitNotify: function () {
      $(document).on("submit", "[data-form-notify]", function (event) {
        event.preventDefault();
        template_store.submitFormNotify($(this));
      });
    },
    inputGift: function () {
      //inputs e labels - gift tag
      document.body.addEventListener("click", function (e) {
        if (e.target && e.target.matches("[data-input-gift]")) {
          
          if (e.target.value == "sim") {
            e.target.value = "não";
            e.target.nextElementSibling.classList.remove("label-active");
            
            console.log("eii", e.target.value);
          } else {
            e.target.value = "sim";
            e.target.nextElementSibling.classList.add("label-active");
            
            console.log("eii 2", e.target.value);
          }
        }
      });
    },
    setProductsRelated: () => {
      const relatedProducts = document.querySelectorAll('[data-related]');
  
      relatedProducts.forEach(section => {
        const carousel = section.querySelector('.swiper');
        const pagination = section.querySelector('.swiper-pagination');
        const next = section.querySelector('.swiper-button-next.related');
        const prev = section.querySelector('.swiper-button-prev.related');
  
        new Swiper(carousel, {
          slidesPerView: 4,
          watchOverflow: true,
          preloadImages: false,
          autoplay:{
            speed: 2000
          },
          lazy: {
            checkInView: true,
            loadPrevNext: false,
            loadOnTransitionStart: true,
          },
          navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: '-disabled'
          },
          breakpoints:{
            320:{
              slidesPerView: 1,
              spaceBetween: 15
            },
            992:{
              slidesPerView: 4,
              spaceBetween: 15
            }
          }
        });
      });
    },
    init: function () {
      var _this = this;
      _this.setProductsRelated();
      _this.showFirstImages();
      _this.setGallery();
      _this.submitNotify();
      _this.setQuantityProduct();
      _this.inputGift();
      
      $(document).on(
        "submit",
        store.config.selectorFormProduct,
        function (event) {
          event.preventDefault();

          var $form = $(this);
          var $parent = $form.closest(store.config.selectorWrapperProduct);

          var $btnComprar = $parent.find(store.config.selectorBtnBuy);

          var respValidated = store.validateFormProduct($form);
          var $boxResponse = $parent.find(".resp-validate");

          if (respValidated.validated) {
            store.addItem($form, $parent);
          } else {
            console.info("não foi");
            store.setRespValidateProduct(respValidated, $form, $boxResponse);
          }
        }
      );

      $(function () {
        $("[data-display-tooltip]").tooltip();
      });
    },
  },

  tag: {
    setOpenFilters: function () {
      var mobileOpenFilters = document.querySelector("[data-open-filters]"),
        filtersWrapper = document.querySelector("[data-filters-toggle]"),
        showItemFilter = document.querySelectorAll("[data-toggle-item-filter]"),
        itemFilter = document.querySelectorAll("[data-filter-item]");
        
        if (mobileOpenFilters) {
          
          mobileOpenFilters.addEventListener("click", () => {
            filtersWrapper.classList.toggle("-open");
          });

          showItemFilter.forEach((showItemFilter, i) => {
            showItemFilter.addEventListener("click", (event) => {
              itemFilter[i].classList.toggle("-open");
            });
          });
          
        }

      
    },

    setFilterSelected: function () {
      var sPageURL = decodeURI(window.location.search.substring(1)),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        // console.log('nome do parametro', sParameterName[0])
        // console.log('valor do parametro', sParameterName[1])

        if (
          $(
            '#filtros [name="' +
              sParameterName[0] +
              '"][value="' +
              sParameterName[1] +
              '"]'
          ).length > 0
        ) {
          // console.log('achou')
          $(
            '#filtros [name="' +
              sParameterName[0] +
              '"][value="' +
              sParameterName[1] +
              '"]'
          )
            .closest("li")
            .addClass("selected");
          $(
            '#filtros [name="' +
              sParameterName[0] +
              '"][value="' +
              sParameterName[1] +
              '"]'
          ).attr("checked", true);
        }
      }
    },
    setFilterPrice: function () {
      var timer;
      console.log("> setFilterPrice");
      if ($("#filter-price").length > 0) {
        var $filterPrice = $("#filter-price").bootstrapSlider({
          max: 599.99,
          precision: 2,
          tooltip: 'always',
          formatter: function (value) {
            if (Array.isArray(value)) {
              $('.min-price').text(store.formatMoney(value[0]));
              $('.max-price').text(store.formatMoney(value[1]));
              return (
                store.formatMoney(value[0]) +
                " - " +
                store.formatMoney(value[1])
              );
            } else {
              return store.formatMoney(value);
            }
          },
        });
        $filterPrice.on("slide", function (sliderValue) {
          clearTimeout(timer);
        });
        $filterPrice.on("slideStop", function (sliderValue) {
          var $minSelected = $("[data-min-price-selected]");
          var $maxSelected = $("[data-max-price-selected]");
          var $filterMaxPrice = $("[data-max-price]");
          var $filterMinPrice = $("[data-min-price]");

          console.log("filter min", $(this).data("slider-min"));
          console.log("filter max", $(this).data("slider-max"));
          console.log(
            "filter value",
            sliderValue.value[0],
            sliderValue.value[1]
          );

          var min = sliderValue.value[0];
          var max = sliderValue.value[1];

          $filterMinPrice.val(sliderValue.value[0]);
          $filterMaxPrice.val(sliderValue.value[1]);

          // Seta o timer para atualizar os filtros
          timer = setTimeout(function () {
            $filterMaxPrice.closest("form").submit();
          }, 900);
        });
      }
    },

    init: function () {
      var _this = this;
      _this.setOpenFilters();
      _this.setFilterSelected();
      _this.setFilterPrice();
    },
  },
  login: {
    init: function () {
      $("[data-content-login]").each(function (index, el) {
        // Animação
        $(el).on("click", "[data-forget-password]", function (event) {
          event.preventDefault();
          $(el).find(".wrapper-form-login").animate({ height: "toggle" }, 500);
          $(el)
            .find(".wrapper-form-recover")
            .animate({ height: "toggle" }, 500);
        });

        $(el).on("click", "[data-forget-password-back]", function (event) {
          event.preventDefault();
          $(el).find(".wrapper-form-login").animate({ height: "toggle" }, 500);
          $(el)
            .find(".wrapper-form-recover")
            .animate({ height: "toggle" }, 500);
        });

        $(el).on("submit", "[data-form-login]", function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateLogin($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, "error", $(this));
          }
        });

        $(el).on("submit", "[data-form-register]", function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRegister($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, "error", $(this));
          }
        });

        $(el).on("submit", "[data-form-recover]", function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRecover($form);

          if (respValidated.validated) {
            store.login.recoverPassord($form);
          } else {
            store.setMsgResponse(respValidated.errors, "error", $(this));
          }
        });
      });
    },
  },

  init: function () {
    var _this = this;
    store.getCartCount();
    CartDrawer.init()

    // ENVIO DE FORMULÁRIOS
    $("[data-webform]").on("submit", function (event) {
      event.preventDefault();
      var $form = $(this);
      var vnda = $(this).find('[name="vnda"]');

      if (vnda.length > 0) {
        if (vnda.val() != "") {
          //console.info('ROBOT DETECTED');
          return false;
        }
      }

      store.submitForm($form);
    });
  },
};

window.addEventListener("DOMContentLoaded", () => {
  template_store.init();

  if (page == "home") {
    template_store.home.init();
  }

  if (page == "product") {
    template_store.product.init();
  }

  if (page == "tag") {
    template_store.tag.init();
  }

  if (page == "login") {
    template_store.login.init();
  }
});

console.log(
  "%cVnda - Tecnologia em Ecommerce",
  'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;'
);
